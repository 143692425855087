.advanced-search-page{
    // background-color: lime;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
}

.advanced-search-page .advanced-search-page-wrapper{
    // background-color: pink;
    width: 95%;
    height: calc(100vh - 3rem);
    display: flex;
    justify-content: center;
    align-items: center;
}

.advanced-search-page .advanced-search-page-wrapper-inner{
    background-color: pink;
    background-color: rgb(240, 240, 240);
    padding: 1.5rem;
    border-radius: 1rem;
    min-width: 75%;
    min-height: 70%;
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    gap: 1rem;
    // width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.available-criteria-items{
    // background-color: beige;
    display: flex;
    gap: 2rem;
    min-height: 2rem;
    border-bottom: 1px solid rgba(50, 49, 49, 0.552);
    padding-bottom: 1rem;
}


.available-criteria-item{
    // background-color: beige;
    // background-color: greenyellow;
    background-color: white;
    padding: 0.3rem 0.1rem 0.3rem 0.8rem;
    border-radius: 0.5rem;
    display: flex;
    gap: 0.9rem;
    align-items: center;
    line-height: 1rem;
    cursor: pointer;
    border: 1px solid rgba(202, 202, 202, 0.801);
}


.advanced-search-page select{
    border: none;
    outline: none;
    // background-color: beige;

}

.advanced-search-page input{
    border: none;
    outline: none;
    // background-color: beige;

}

.advanced-search-page .advanced-search-criteria{
    // background-color: pink;
    // background-color: lime;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.advanced-search-page .advanced-search-criterion{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    line-height: 0;
    // background-color: aqua;
    // width: 100%;
    // background-color: beige;
    width: 100%;
    justify-content: space-between;

}

.advanced-search-page .adv-criterion-indicator{
    width: 15%;
    // width: 100%;
    // background-color: yellow;
    // background-color: beige;
    background-color: white;
    padding: 0.35rem;
    display: flex;
    align-items: center;
    line-height: 1rem;
    gap: 0.5rem;
    height: 100%;
    border-radius: 0.2rem;

}

.adv-criterion-match-container{
    width: 10%;
    // background-color: palegreen;
}

.advanced-search-page .adv-criterion-match-select{
    padding: 0.2rem;
    border-radius: 0.2rem;
}

.advanced-search-page .adv-criterion-input-container{
    width: 60%;
// background-color: yellow;
// width: 55%;
}
.adv-input-year-container{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.adv-input-year{
    width: 45%;
    // flex: 1;
}

.advanced-search-page .adv-criterion-delete-icon{
    // color: red;
    cursor: pointer;
    // background-color: red;
    width: 5%;
}

.advanced-search-page .adv-criterion-input{
    padding: 0.2rem;
    border-radius: 0.2rem;
    width: 100%;
}


.adv-criterion-matchall-select-div{
    // margin: 1rem 0rem;
    // width: 100%;
    font-size: 0.9rem;
    background-color: white;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    height: max-content;
}

.adv-criterion-matchall-select-div span{
    background-color: white;
    height: 100%;
}
.adv-criterion-matchall-select-div select{
    background-color: white;
    height: 100%;
}

.adv-criterion-matchall-select-div-outer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}


.adv-page-last-section{
    display: flex;
    // flex-direction: column;
    width: 100%;
    background-color: ghostwhite;
    align-items: center;
    // align-items: flex-end;
    
    // justify-content: flex-end;
    // justify-content: flex-start;
    // justify-content: center;
    // gap: 5rem;
    // justify-content: space-around;
    // justify-content: space-between;
    // padding: 0rem 2rem;
    // justify-content: space-evenly;
}

.adv-go-button-div{
    width: 95%;
    display: flex;
    justify-content: flex-end;
}

.adv-go-button{
    border: none;
    padding: 0.5rem 1rem;
    background-color: rgb(2, 81, 112);
    background-color: rgb(23, 118, 155);
    border-radius: 0.3rem;
    color: white;


}

.inherit-whatever{
    // display: none;
    width: auto;
    height: auto;
    width: max-content;
    height: max-content;
    background-color: pink;
}

