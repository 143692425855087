.previewServerDocumentPage{
    width: 100%;
    display: flex;
    justify-content: center;
}

.previewServerDocumentPage-inner{
    width: 70%;

    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    // background-color: pink;
}