


.topbar{
    background-color: gray;
    background-color: white;
    background-color: rgb(241, 241, 241);
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(110, 110, 110, 0.261);
    font-size: 0.85rem;
    font-size: 300;
    // height: 5rem !important;
    // background-color: #f5d7d7
    background-color: rgb(5, 118, 194);
    color: white;
}

.topbar-wrapper{
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 1rem;
}

.topbar-wrapper-left{
    width: 10%;
    // background-color: pink;
    cursor: pointer;
}

.topbar-wrapper-center{
    width: 30%;
    // background-color: pink;
    // height: 40%;
    // height: 100%;
}

.topbar-wrapper-right{
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.topbar-wrapper-right-item1, .topbar-wrapper-right-item2, .topbar-wrapper-right-item3, .topbar-wrapper-right-item4{
    display: flex;
    align-items: center;
    line-height: 1rem;
    cursor: pointer;
    position: relative;
    // background-color: aqua;
    // width: 20vw;
}

.topbar-dropdown-ui-container{
    position: absolute;
    // z-index: 9999999999999999999999999999;
    right: 1rem;
    // right: 5rem;
    top: 2rem;
    // background-color: yellow;
    // display: none;
    // height: max-content;
    // width: max-content;
    background-color: rgb(5, 118, 194);

}

.topbar-dropdown-ui-items{
    // background-color: rgb(247, 238, 238);
    background-color: white;
    background-color: rgb(241, 241, 241);

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    // background-color: greenyellow ;
    // display: none;
    flex-direction: column;
    // color: red;
    // background-color: blue;
    gap: 0.1rem;
    width: max-content;
    // width: fit-content;
    // align-items: flex-end;
    // border-bottom-left-radius: 0.5rem;
    // border-bottom-right-radius: 0.5rem;
    // height: max-content;
    // width: max-content;
    background-color: rgb(5, 118, 194);

}

.topbar-dropdown-ui-item{
    line-height: 1rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    border-bottom: 1px solid rgba(199, 197, 197, 0.178);
    padding: 1rem;
    padding-left: 0rem;
    // padding-bottom: 1.5rem;
    background-color: pink;
    width: 100%;
    justify-content: flex-end;
    background-color: rgb(241, 241, 241);
    background-color: rgb(5, 118, 194);


}

