.sidebar-component{
    font-size: 0.6rem;
}

// .dummy-container{
//     width: 50%;
//     height: 100%;
//     height: 90vh;
//     background-color: pink;
// }

.reusable-filter-results-box{
    // background-color: beige;
    border: 1px solid rgba(225, 225, 245, 0.185);
    width: 100%;
    // height: 90%;
    height: 100%; 
    // max-height: 100vh;
    // display: none;
    font-size: 0.6rem;
    height: calc(100vh - 4rem);

    // height: 100vh;
    // background-color: pink;
    // padding: 0.5rem 1rem;
     

    // overflow-y: scroll;
}

.reusable-filter-results-box-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // gap: 1rem;
    // padding: 0.5rem 1rem;
    padding: 0rem 1rem;
    padding-right: 0rem;


    // align-items: center;
    // background-color: lime;

    // background-color: yellow;
    height: 100%;

    // overflow-y: scroll;
    // position: relative;
}

.rfr-heading{
    position: relative;
    display: flex;
    align-items: center;
    // background-color: lime;
    height: 5%;
    margin-top: 1rem;
    font-size: 0.8rem;
}

.rfr-heading > h1{
    margin: 0;
    text-align: center;
    width: 100%;
    font-weight: 300;
    font-size: 1rem;
    // display: none;
}

.rfr-sidebar-toggler{
    // background-color: yellow;
    // line-height: 1rem;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    font-weight: 300;
    color: gray;
    // top: r;
    // width: 100%;
    // top: 0;
}

.rfr-sidebar-toggler-items{
    display: flex;

    width: 4rem;
    // height: 1rem;
    // background-color: cyan;
    line-height: 1rem;
    cursor: pointer;
    justify-content: flex-end;
    gap: 0.2rem;
}

.rfr-sti-first{
    // border: 1px solid black;
    padding: 0.1rem;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    // justify-content: center;

    line-height: 1rem;
    display: flex;
    align-items: center;

    line-height: 1rem;
    // font-size: 1.2rem;
    // background-color: pink;
    // display: none;
}
.rfr-sti-second{
    // border: 1px solid black;
    padding: 0.1rem;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;

    line-height: 1rem;
    // font-size: 1.2rem;
}

.rfr-sti-third{
    display: flex;
    align-items: center;

    line-height: 1rem;
    // font-size: 1.2rem;
}


.reset-buttons{
    // width: 100%;
    display: flex;
    align-items: center;
    // gap: 1rem;
    font-weight: bold;
    width: 100%;
    justify-content: center;
    // height: 5%;
    // background-color: greenyellow;
    margin-bottom: 0.5rem;
}

.reset-button-item{
    cursor: pointer;
    // border: 1px solid gray;
    color: rgb(78, 78, 78);
    background-color: ghostwhite;
    // border-radius: 0.4rem;
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
    font-weight: 300;
    flex: 1;
    border: 1px solid rgba(128, 128, 128, 0.15);
    // border: none;
}

.reusable-filter-results-box-wrapper-main{
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.8rem;
    // flex-grow: inherit;
    // font-size: 1rem;
    // background-color: cyan;
    margin-bottom: 1rem;
    padding-right: 1rem;
}

  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #03010923, #3e3e3ec2);

    border-radius: 2px;
  }
  
  /* Handle on hover */
  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #03010923, #3e3e3ec2);
  }

  
  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #03010923, #3e3e3ec2);
    border-radius: 2px;
  }
  
  /* Handle on hover */
  .reusable-filter-results-box-wrapper-main::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #03010923, #3e3e3ec2);
  }
    

.reusable-filter-results-box label{
    cursor: pointer;
}

.reusable-filter-results-box summary{
    cursor: pointer;
}

.reusable-filter-results-box-wrapper{
    
}

.sort-by-detail-wrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // background-color: lime;
    position: relative;
    width: 100%;
}

.sort-by-detail-wrapper-inner{
  position: relative;
  // background-color: yellow;
}

.sort-by-select{
  padding: 0.5rem;
  margin-top: 0.7rem;
  width: 100%;
  border: 1px solid rgb(187, 187, 187);
}

.option{
  // padding: 2rem;
}

.sort-by-detail-wrapper .multi-range-slider{
  margin-top: 2rem;
}


.multi-range-sliderV{
  // background-color: pink;
  padding: 0rem 1.5rem;
  max-width: 90%;
  margin-top: 1rem;
}

.clear-checkbuttons{
  // background-color: pink;
  width: 100%;
  text-align: right;
  // cursor: pointer;
  // position: absolute;
  top: 0;
  right: 0;
}

.clear-checkbuttons button{
  border: none;
  font-size: 0.7rem;
  background-color: white;
  // background-color: pink;
  font-weight: 300;
}

.clear-checkbuttons .clear-buttonxyz{
  border-right: 1px solid gray;
  // background-color: red;
}


// .sort-by-detail-wrapper .clear-button{
//   // background-color: pink;
//   width: 100%;
//   text-align: right;
//   // cursor: pointer;
//   position: absolute;
//   top: 0;
//   right: 0;
// }

// .clear-button > button{
//   // border: none;
//   // outline: none;
//   cursor: pointer;
// }

.apply-filters-button-container{
  width: 100%;
  // padding: 0rem 0.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // justify-content: center;
  height: 5%;
  margin-bottom: 0.5rem;
  // background-color: greenyellow;
}

.apply-filters-button-container-button{
  width: 95%;
  border-radius: 0.2rem; 
  border: none;
  padding: 0.5rem 0rem;
  font-size: 0.8rem;
  background-color: peru;
  background-color: rgba(0, 0, 0, 0.74);
  color: white;
  font-weight: 300; 
  background-color: rgb(1, 129, 180);
  // display: none;
  // color: red;
}

.apply-filters-button-container-button:disabled{
  // background-color: red;
  background-color: rgba(105, 188, 221, 0.575);

  // background-color: rgba(64, 175, 219, 0.596);
  color: rgba(0, 0, 0, 0.562);

}


.set-req-query-page-size-container{
  width: 100%;
  // display: flex;
  // justify-content: flex-end;
  // align-items: center;
  // align-items: flex-end;
  // font-size: 0.8rem;
  height: 10%;
  // background-color: yellow;
}

.set-req-query-page-size-container-inner{
  display: flex;
  // align-items: flex-end;
  // align-items: center;
  gap: 0.4rem;
  // background-color: green;
  // display: none;
  width: 100%;
  // height: 100%;
  justify-content: flex-end;
  // align-items: flex-start;
  align-items: center;
  // margin-top: 0.5rem;
  padding-top: 0.5rem;
  font-weight: 300;
  // font-weight: 100;
  font-size: 0.7rem;
  
}

.set-req-query-page-size-container-inner-2{
  // background-color: pink;
  // padding: 0rem 1rem;
  display: flex;
  align-items: center;
  height: 35%;
  border-radius: 0.5rem;
  width: 40%;
  justify-content: flex-end;
  overflow: hidden;
  border: 1px solid rgba(128, 128, 128, 0.541);
  // padding: 0.2rem;
  padding-left: 0.2rem;
  font-size: 0.6rem;
}

.set-req-query-page-size-container-inner-2 input{
  border: none;
  // border: 1px solid red;
  // border-bottom: 1px solid black;
  padding: 0;
  // line-height: 1rem;
  // padding-top: 0.5rem;
  width: 50%;
  height: 100%;
  border-right: none;
  // font-size: 0.9rem;
  padding: 0.1rem;
}

.set-req-query-page-size-container-inner button{
  border: none;
  // border-radius: 50%;
  background-color: gainsboro;
  // width: 2rem;
  // height: 2rem;
  // padding: 0.3rem;
  height: 100%;
  width: 50%;
  font-weight: 300;
  font-size: 0.7rem;
  // font-weight: 100;
}

.sidebar-maximize-button-container{
    width: 100%;
    // background-color: pink;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    // background-color: snow;
    // background-color: lime;
}

.sidebar-maximize-button-icon-container{
    width: 100%;
    height: 4rem;
    // background-color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-maximize-button-icon{
    width: 100%;
    // background-color: lime;
    height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
    color: rgba(128, 128, 128, 0.501);
    font-size: 1rem;
    // color: red;
}