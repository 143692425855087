.about-supervisor-page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    font-weight: 300;

}


.about-supervisor-page-inner{
    display: flex;
    width: 85%;
    flex-direction: column;
}

.about-supervisor-name{
    margin-top: 1rem;
    font-size: 1.2rem;
}

.about-supervisor-image-section{
    width: 100%;
    height: 5rem;
}

.about-supervisor-image-container{
    width: 5rem;
    height: 5rem;
}

.about-supervisor-image-itself{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
}

.about-supervisor-about{

}

.about-supervisor-socials{
    // display: flex;
    margin-bottom: 1rem;

}

.about-supervisor-socials-buttons{
    display: flex;
    gap: 1rem;
}

.about-supervisor-socials-buttons span{
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    // gap: 1rem;
}

.about-supervisor-others{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

}

.about-supervisor-keywords-details{

}

.about-supervisor-keywords-container{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.about-supervisor-page-keyword-item{
    border: 1px solid gainsboro;
    padding: 0.1rem 0.5rem;
    border-radius: 0.3rem;

}

.about-supervisor-theses-details{

}

.about-supervisor-theses-container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    border-color: red;
}



.about-supervisor-users-details{
    // padding-top: 0.5rem;

}

.about-supervisor-users-container{
    display: flex;

    width: 100%;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

}
.about-supervisor-page-user-item{
    border: 1px solid gainsboro;
    padding: 0.2rem 0.5rem;
    border-radius: 0rem 1rem 0rem 0rem;
    // margin-top: 0.5rem;

}










