.view-thesis-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: whitesmoke;
    font-size: 0.8rem;
}

.view-thesis-top-header{
    background-color: beige;
    background-color: white;
    padding-top: 1rem;
    // height: 10rem;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(105, 105, 105, 0.194);
}

.view-thesis-top-header-inner{
    width: 75%;
    // background-color: gray;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.view-thesis-top-header-type{
    display: flex;
    gap: 1rem;
}

.view-thesis-top-header-type-item{
    border: 1px solid rgba(58, 58, 58, 0.148);
    padding: 0rem 0.8rem;
    // font-size:  1rem;
    color: rgb(53, 53, 53);
}

.view-thesis-top-header-title{
    font-size: 1.2rem;
    font-weight: bold;
}

.view-thesis-top-header-date{
    // font-size: 0.9rem;
    color: rgb(59, 59, 59);
    display: flex;
    gap: 0.6rem;
}

.view-thesis-top-header-menu-bar{
    border-top: 1px solid rgba(85, 85, 85, 0.154);
    // padding: 0rem 0.5rem;
    display: flex;
    // justify-content: space-around;
    justify-content: space-between;
    margin-top: 1rem;
    color: rgb(66, 65, 65);
}

.view-thesis-top-header-menu-bar-item{
    cursor: pointer;
    // background-color: pink;
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
    // font-size: 0.9rem;
}

.view-thesis-top-header-menu-bar-item.active{
    border-bottom: 2px solid rgb(72, 122, 198);
    // background-color: lime;
}

.view-thesis-main{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1.5rem;
}

.view-thesis-main-inner{
    display: flex;
    width: 75%;
    justify-content: space-between;
    gap: 1rem;
}

.view-thesis-main-body{
    background-color: cyan;
    background-color: white;
    width: 65%;
    height: max-content;
}

.view-thesis-main-abstract{
}

.view-thesis-main-abstract-head{
    border-bottom: 1px solid rgba(75, 75, 75, 0.239);
    padding: 1rem;

}

.view-thesis-main-abstract-body{
    padding: 1rem;
    // font-size: 0.9rem;
}






.view-thesis-minor-right{
    // background-color: yellow;
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.vtmr-keywords{
    background-color: pink;
    background-color: white;
}

.vtmr-keywords-header{
    padding: 1rem;
    border-bottom: 1px solid rgba(114, 113, 113, 0.222);
}

.vtmr-keywords-body{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 1rem;
}

.vtmr-keywords-body-item{
    border: 1px solid rgba(78, 77, 77, 0.25);
    padding: 0.1rem 1rem;
    border-radius: 0.5rem;
    color: rgb(71, 70, 70);
    background-color: azure;
    // font-size: 0.9rem;
}

.vtmr-citation{
    background-color: bisque;
    background-color: white;

}

.vtmr-citation-head{
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid rgba(129, 129, 129, 0.239);
    justify-content: space-between;
    align-items: center;
    line-height: 1rem;
    cursor: pointer;
}

.vtmr-citation-body{
    padding: 1rem;
    // font-size: 0.9rem;
}


.vtmr-others-suggested{
    background-color: burlywood;
    background-color: white;

}

.vtmr-others-suggested-head{
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid rgba(116, 116, 116, 0.302);
    justify-content: space-between;
    align-items: center;
    line-height: 1rem;
}

.vtmr-others-suggested-body{    
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.vtmr-others-suggested-body-item{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.vtmr-others-suggested-body-item-title{
    // font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;

}

.vtmr-others-suggested-body-item-details{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.vtmr-research{
    // font-size: 0.8rem;
    background-color: rgb(190, 221, 225);
    padding: 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.vtmr-year{
    // font-size: 0.8rem;
    color: rgb(85, 85, 85);
}

.vtmr-others-suggested-body-view-more{
    // display: flex;
    padding: 1rem;
    text-align: center;
    border-top: 1px solid rgba(172, 172, 172, 0.205);
    // font-size: 0.8rem;
    color: rgb(31, 112, 152);
    cursor: pointer;
}

.view-thesis-page-more-manage-thesis-section{   
    display: flex;
    align-items: center;
    line-height: 1rem;
    // background-color: lime;
    cursor: pointer;
}

.view-thesis-page-more-manage-thesis-section-button{
    padding: 1rem 3rem 1rem 2rem;
    border: 1px solid rgba(173, 173, 173, 0.281);
    border-radius: 0.4rem;
    // border: none;
    // padding-right: 2.5rem;
}

.view-thesis-page-more-manage-thesis-section-icon{
    font-size: 3rem;
}



//page full_text
.view-thesis-full-text{
    width: 100%;
}

.view-thesis-full-text .full-text-request-div{
    width: 100%;
    // background-color: pink;
    // width: 25%;
    // padding: 1rem;
}

.view-thesis-full-text .full-text-request-div-inner{
    width: 25%;
    // background-color: pink;
    // width: 25%;
    padding: 1rem;
}


.view-thesis-full-text .full-text-request-button{
    width: 100%;
    // padding-left: 5rem;
    // padding: 0rem 5rem;
}


.view-thesis-full-text-head{
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.081);
    // background-color: yellow;
    width: 100%;

}

.view-thesis-full-text-head-left{
    // background-color: lime;
    background-color: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    // justify-content: center;
    line-height: 0.5rem 1rem;
    gap: 1rem;
    width: 25%;
    border-right: 1px solid rgba(0, 0, 0, 0.117);
    // border-bottom: none;
    // border-left: none;
    // width: max-content;
}

.view-thesis-full-text-head-icon{
    line-height: 1rem;
}

.view-thesis-full-text-head-right{
    width: 75%;
    display: flex;
    align-items: center;
    // background-color: orange;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.081);

}


.view-thesis-full-text-head2-right{
    // background-color: yellow;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // padding: 1rem;
    line-height: 1rem;
    // font-size: 0.9rem;
    color: rgba(75, 75, 75, 0.726);
}

.view-thesis-full-text-head2-right-icon{
    // padding: 1rem;
    // background-color: yellow;
}

.view-thesis-full-text-head2-right-inner{
    padding: 1rem;
    display: flex;
    align-items: center;
    line-height: 1rem;
}

.view-thesis-full-text-body{
    // border-bottom: 1px solid rgba(122, 122, 122, 0.503);
    // border-top: 1px solid rgba(122, 122, 122, 0.503);
    min-height: 10vh;
    margin-bottom: 1rem;
    background-color: pink;
    background-color: white;
    display: flex;
    justify-content: center;
    width: 100%;

    // overflow: hidden;

}

.view-thesis-full-text-body-inner{
    height: 90%;
    width: 90%;
    border: 1px solid black;
}









///FOR FULL TEXT DIALOG
.view-thesis-mftp{
    width: 100%;
    // background-color: yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    font-size: 0.8rem;
}

.view-thesis-mftp select{
    border: none;
}

.amap-fullscreen-modal-body-inner{
    width: 85%;
    display: flex;
    flex-direction: column;
    // align-items: center;
}

.amap-fullscreen-modal-header{
    width: 100%;
    background-color: pink;
    display: flex;
    height: 4rem;
    justify-content: center;
    background-color: rgb(0, 183, 255);
}

.amap-fullscreen-modal-header-inner{
    width: 90%;
    // background-color: yellow;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

}

.amap-fullscreen-modal-header-inner .close{
    font-size: 1.3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1rem;
    cursor: pointer;
}

.amap-fullscreen-modal-header-inner .title{
    font-size: 1.1rem;
}

.amap-fullscreen-modal-header-inner .update{
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    // font-weight: bold;

}

.fetched-full-text-reusable-items{
    margin-top: 1rem;
    // background-color: pink;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}







//reusable item itself
.reusable-full-text-item{
    border: 1px solid gainsboro;
    border-radius: 0rem 0.5rem 0rem 0.5rem;
    font-size: 0.75rem;
    padding: 1rem;
}

.reusable-full-text-item-inner{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.reusable-full-text-item-intro{

}

.reusable-full-text-item-inner-message{

}


.reusable-full-text-item-full-details-button-container{
    display: flex;
    gap: 0.5rem;
    // cur
}

.reusable-full-text-item-full-details-button-container button{
    font-size: 0.75rem;
    cursor: pointer;
    border: none;
    background-color: gray;
    color: white;
    padding: 0.1rem 1rem;
}

.reusable-full-text-item-action-buttons{
    display: flex;
    gap: 1rem;
    // font-size: 0.75rem;
    margin-top: 1rem;
    // background-color: #faa3bd;
}

.reusable-full-text-item-action-buttons button{
    // border: 1px solid none;
    // background-color: red;
    // font-weight: 0.5rem;
    // font-weight: 300;
    border: none;
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
    font-weight: 300;

}

.reusable-full-text-item-action-button-approve{
    background-color: rgb(2, 80, 100);
    color: white;

}

.reusable-full-text-item-action-button-deny-short{
    background-color: rgba(255, 255, 0, 0.226);
    // border

}

.reusable-full-text-item-action-button-push-down{
    background-color: gainsboro;
}

.reusable-full-text-item-action-button-deny-long{
    background-color: rgba(245, 193, 182, 0.247);
    // color: white;
}





