/* HorizontalScrollMenu.css */

.horizontal-scroll-menu {
  display: flex;
  align-items: center;
  // overflow: hidden;
  width: 100%;
  // background-color: yellow;
  justify-content: space-between;
  height: 100%;
  // background-color: cyan;
  
}

.horizontal-scroll-menu-left-button {
  background: none;
  border: none;
  cursor: pointer;
  // background-color: aqua;
  width: 5%; //10% times 2 
  // display: none;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.452);
  height: 100%;
}
.horizontal-scroll-menu-right-button {
  background: none;
  border: none;
  cursor: pointer;
  // background-color: aqua;
  width: 5%; //10% times 2 
  // display: none;
  display: flex;
  align-items: center;
  // justify-content: center;
  justify-content: flex-end;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.452);
  height: 100%;

}

.menu-container {
  display: flex;
  transition: transform 0.3s;
  width: 85%;
  //5% is unaccounted for
  // border-color: limegreen;
  max-width: 100%;
  overflow: hidden;
  // padding-top: 1rem;
  flex-grow: 1;
}

.menu-items {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  // background-color: gold;
  transition: transform 0.3s;
  gap: 2rem;
  // justify-content: space-between;
  // background-color: pink;
  // width: 100%;


}

.horizontal-scroll-menu .menu-item{
  // padding: 1rem;
  // width: 5rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-width: max-content;
  max-width: max-content;
  // width: 15rem;

  // width: max-content;
  // max-width: max-content;
  // width: auto;
  // background-color: pink;
  cursor: pointer;
  font-size: 0.85rem;
  // margin-right: 1rem; /* Adjust the spacing between items */
}

.menu-item.active{
  border-bottom: 2px solid rgba(0, 0, 0, 0.425);
}

@media screen and (max-width: 900px) {
  .menu-item{
    // padding: 1rem;
  }
}


