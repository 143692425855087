@import '../../../app.scss';



.pdf-div{
    // padding: 50px;
    background-color: #f1f1f1;
    // margin-top: 50px;
    // background-color: pink;
    padding: 30px;


    @include media(mobile, tablet){
        padding: 30px 0.5rem;


        margin-top: 0;
        // margin-bottom: 30px;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
        // display: none !important;
    }
}

.react-pdf__Document{
    
    @include media(mobile, tablet){
        // margin-bottom: 30px;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
        // display: none !important;
    }


}

.react-pdf__Page{
    

    @include media(mobile, tablet){
        // margin-bottom: 30px;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
        // display: none !important;
    }



}



.react-pdf__Page__canvas {
    // margin-bottom: 50px;
    // background-color: lime;
    margin-bottom: 50px;


    @include media(mobile, tablet){
        margin-bottom: 20px;

        // margin-bottom: 30px;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
        // display: none !important;
    }

}

