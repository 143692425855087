

.admin-page-page-settings-component{
    width: 100%;
    // background-color: pink;
}

.admin-page-page-settings-component-inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin-page-page-settings-item{
    display: flex;
    width: 100%;
    gap: 1rem;

}

.admin-page-page-settings-item-label{
    width: 30%;
}

.admin-page-page-settings-item-input{
    width: 20%;
}

.admin-page-page-settings-item-button{

}

