.view-all-theses-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    // justify-content: center;
    align-items: center;
    // min-height: 100%;
    height: calc(100% + 4rem);
    // background-color: yellow;
}

.view-all-theses-page-wrapper{
    width: 90%;
    height: 100%;
}

.view-all-theses-page .homepage-wrapper-random-suggestions{
    margin-top: 0;
    // display: flex;
    // flex-direction: column;
    // gap: 0.5rem;
}

.view-all-theses-page .homepage-wrapper-random-suggestions-wrapper-items{
    // margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.view-all-theses-page .homepage-paginationComponent-retainer{
    position: fixed;
    // margin-top: 2rem;
    // margin-bottom: 1rem;
    padding: 0.5rem 0rem;
    background-color: white;
    // background-color: pink;
    // position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    // align-items: center;
    // justify-content: center;
    
}

.view-all-theses-page .homepage-paginationComponent-retainer-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: yellow;
    width: 85%;
    gap: 2rem;

}


.view-all-theses-page .sort-results-by-select{
    border: 1px solid gainsboro;
    color: gray;
    outline: none;
}

.view-all-theses-page .amappc-bottom-right-settings-icon-container{
    color: gray;
}



