@import '../../../app.scss';

.create-thesis-add-document-page{
    width: 100%;
    min-height: 100%;
}

.create-thesis-add-document-page .create-thesis-add-document-page-wrapper{
    width: 100%;
    min-height: 100%;
}

.create-thesis-add-document-page .add-research-top{
    width: 100%;
    background-color: pink;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.22);
    // color: #c1c1c1;
}

.create-thesis-add-document-page .add-research-top-inner{
    display: flex;
    align-items: center;
    background-color: gainsboro;
    width: 60%;
    padding: 0rem 4rem 0rem 0rem;
    gap: 1rem;
    line-height: 1rem;
}

.create-thesis-add-document-page .add-research-top-inner-icon-container{
    font-size: 2rem;
    border: 2px solid rgb(55, 54, 54);
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(53, 53, 53);
    // font-weight: 100;
    
}

.create-thesis-add-document-page .add-research-top-inner-icon{

}

.create-thesis-add-document-page .add-research-top-inner-text{
    font-size: 1.2rem;
}

.create-thesis-add-document-page .add-research-middle{
    // background-color: yellow;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: gainsboro;
}

.create-thesis-add-document-page .add-research-middle-inner{
    // background-color: lime;
    width: 60%;
    height: max-content;
    padding: 2rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.create-thesis-add-document-page .add-research-middle-inner-prompt{
    // font-weight: bold;
    font-weight: 400;
    font-size: 1.2rem;
}

.create-thesis-add-document-page .add-research-middle-inner-select-file{
    border: 1px solid blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    color: blue;
    display: flex;
    gap: 0.5rem;
    line-height: 1rem;
    // background-color: pink;
}

.create-thesis-add-document-page .add-research-middle-inner-select-file:hover{
    background-color: blue;
    color: white;
}

.create-thesis-add-document-page .add-research-middle-inner-select-file-icon-container{
    font-size: 2rem;
}

.create-thesis-add-document-page .add-research-middle-inner-skip-section{
    border-top: 1px solid rgba(107, 107, 107, 0.517);
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 0.5rem;
    // align-items: center;
}

.create-thesis-add-document-page .add-research-middle-inner-skip-section-left{
    display: flex;
    gap: 0.25rem;
    align-items: center;
    line-height: 1rem;
    // font-size: 0.7rem;
}

.create-thesis-add-document-page .add-research-middle-inner-skip-section-left-text{
    font-size: 0.9rem;
}

.create-thesis-add-document-page .add-research-middle-inner-skip-section-right{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.create-thesis-add-document-page .add-research-middle-inner-skip-section-right-skip{
    color: rgb(34, 34, 239);
    cursor: pointer;
}

.create-thesis-add-document-page .create-thesis-add-document-page .add-research-middle-inner-skip-section-right-skip:hover{
    text-decoration: underline;
}

.create-thesis-add-document-page .add-research-middle-inner-skip-section-right-go{
    background-color: blue;
    color: white;
    padding: 0.2rem 1.2rem;
    border-radius: 1rem;
}





.add-research-uploaded-document{
    border: 1px solid rgba(117, 117, 117, 0.251);
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
}

.add-research-uploaded-document-left{
    width: 10%;
    // background-color: pink;
}

.add-research-uploaded-document-left-icon-container{
}

.add-research-uploaded-document-left-icon-container-icon{
    all: unset;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    line-height: 1rem;
    color: rgb(107, 104, 104);
}

.add-research-uploaded-document-right{
    width: 80%;
}


.add-research-uploaded-document-rt-name{

    font-weight: bold;
    font-size: 0.9rem;
    width: 100%;

    height: max-content;
    display: flex;
    flex-wrap: wrap;
    // background-color: lime;
    // display: flex;
    // flex-wrap: nowrap;
    // align-items: center;
    // height: 1rem;
    // overflow: hidden;
}


.add-research-uploaded-document-rb{
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    // background-color: yellow;

    @include media(mobile, tablet){
        // flex-direction: column;
        // align-items: flex-start;
        // just
        // gap: 0rem;
        // background-color: pink;
        // background-color: lime;
    }
}


.add-research-uploaded-document-rbl-size{

}

.add-research-uploaded-document-rbr-remove{
    cursor: pointer;
    color: rgb(243, 165, 151);
}



